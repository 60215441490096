import { AppProps } from 'next/app'

import 'fomantic-ui-css/semantic.min.css'
import '../styles/index.css'
import 'react-calendar-heatmap/dist/styles.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

import withApollo from '@/lib/withApollo';
import { getDataFromTree } from '@apollo/client/react/ssr';

import config from '@/config';

import * as FA from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

import { Provider } from 'react-redux'
import { useStore } from '@/lib/store'

FA.config.autoAddCss = false

FA.library.add(fas, fad)

//import * as Sentry from '@sentry/node'
//import { RewriteFrames } from '@sentry/integrations'
//import getConfig from 'next/config'

if (!!config.sentry.dsn) {
  /*const nextConfig = getConfig()
  const distDir = `${nextConfig.serverRuntimeConfig.rootDir}/.next`
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    release: config.sentry.release,
    integrations: [
      new RewriteFrames({
        iteratee: (frame: any) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next')
          return frame
        },
      }),
    ],
  })*/
}

function MyApp({ Component, pageProps, err }: AppProps & { err: any }) {
    const store = useStore(pageProps?.initialReduxState)

    return <Provider store={store}>
      <Component {...pageProps} err={err} />
    </Provider>
}

export default withApollo(MyApp, { getDataFromTree });
